import { FC } from 'react'

export const TextCentered: FC = ({ children, ...props }) => {
  return (
    <div className="relative" {...props}>
      <div className="relative flex flex-col justify-center">
        <span className="mx-4 flex flex-col text-center">{children}</span>
      </div>
    </div>
  )
}
