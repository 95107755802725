import type { FC } from 'react'

import { TeeroLogo } from '@people/ui/brand/TeeroLogo'

export const TitleSetup: FC = ({ children, ...other }) => {
  return (
    <div
      className="flex flex-col items-center overflow-x-hidden px-6 lg:px-8"
      {...other}
    >
      <header className="mb-6 flex flex-col sm:mx-auto sm:w-full sm:max-w-md ">
        <a
          href="https://www.teero.com"
          target="_blank"
          className="z-100 mx-auto text-blue-900"
        >
          <TeeroLogo />
        </a>
      </header>
      <div className="max-w-[450px] items-center">{children}</div>
    </div>
  )
}
