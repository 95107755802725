import type { FC } from 'react'
import { GoogleIcon } from '../icons'

import { Button } from '../buttons'
import { ButtonSpinner } from '../spinners/ButtonSpinner'

export interface GoogleLoginButtonProps {
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
}

export const GoogleLoginButton: FC<GoogleLoginButtonProps> = ({
  onClick,
  disabled,
  loading,
  children,
}) => {
  return (
    <>
      <Button
        variant="secondary"
        className="w-full"
        onClick={onClick}
        disabled={disabled}
      >
        {loading ? (
          <ButtonSpinner className="h-6 w-6 animate-spin text-gray-500" />
        ) : (
          <div className="flex h-6 w-6 content-center items-center p-0.5 mr-2">
            <GoogleIcon />
          </div>
        )}
        <span className="text-sm font-medium">
          {children || 'Login with Google'}
        </span>
      </Button>
    </>
  )
}

export default GoogleLoginButton
