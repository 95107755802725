import type { FC } from 'react'
import { Meta, Seo } from '../Seo'
import { TitleSetup } from '../setup/TitleSetup'

export const LayoutSignIn: FC<{ meta?: Meta }> = ({
  meta,
  children,

  ...props
}) => {
  return (
    <>
      <Seo meta={meta} />
      <div
        className="font-primary flex flex-col items-center overflow-x-hidden py-8 px-6 text-slate-900 lg:px-8"
        {...props}
      >
        <TitleSetup />
        <main className="container w-full max-w-[450px]">{children}</main>
      </div>
    </>
  )
}
