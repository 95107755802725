import { LayoutSignIn } from '@/components/signin/LayoutSignIn'
import { TextCentered } from '@/components/signin/TextCentered'
import { ExclamationIcon } from '@heroicons/react/solid'
import { useAuth } from '@hooks/useAuth'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import {
  useSignInWithGoogle,
  useSignInWithMicrosoft,
} from 'react-firebase-hooks/auth'

import { GoogleLoginButton, MicrosoftLoginButton } from '@people/ui/auth'
import { Card } from '@people/ui/cards'
import { useTranslation } from 'next-i18next'

const SignIn = () => {
  const { push, query } = useRouter()
  const { auth, user } = useAuth()
  const [signInWithGoogle] = useSignInWithGoogle(auth)
  const { t } = useTranslation('common')
  const [signinWithMicrosoft] = useSignInWithMicrosoft(auth)

  useEffect(() => {
    if (user && !user.isAnonymous) {
      const redirect = (query.redirect as string) || '/'
      push(redirect)
    }
  }, [user])

  return (
    <>
      {!user && (
        <LayoutSignIn meta={{ title: 'Sign in' }}>
          <TextCentered>
            <span className="mb-8 mt-2 text-2xl font-semibold text-slate-800">
              Sign in to your account
            </span>
          </TextCentered>

          {query.error === 'google-email-not-verified' && (
            <div className="mb-4 border-l-4 border-yellow-400 bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {t('verifyEmailBeforeLogin')}
                  </p>
                </div>
              </div>
            </div>
          )}

          {query.error === 'OAuthAccountNotLinked' && (
            <div className="mb-4 border-l-4 border-yellow-400 bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {t('useOriginalAccountToConfirmIdentity')}
                  </p>
                </div>
              </div>
            </div>
          )}

          <Card>
            {/* <TopCardTabsContainer>
              <Tab className="w-full" current={true}>
                Sign In
              </Tab>
              <Link href="/signup" passHref>
                <Tab className="w-full" current={false}>
                  Sign Up
                </Tab>
              </Link>
            </TopCardTabsContainer> */}
            <div className="p-8">
              <GoogleLoginButton
                onClick={async () => {
                  signInWithGoogle([], { prompt: 'select_account' })
                }}
              >
                {t('loginWithGoogle')}
              </GoogleLoginButton>

              <div className="w-full my-6 "></div>

              <MicrosoftLoginButton
                onClick={async () => {
                  signinWithMicrosoft([], { prompt: 'select_account' })
                }}
              >
                {t('loginWithMicrosoft')}
              </MicrosoftLoginButton>

              {/* <TextCenteredLine>
                <span className="my-6 text-xs font-semibold text-slate-500">
                  {t('orSignInWith')}
                </span>
              </TextCenteredLine>

              <SignInFormEmail /> */}
            </div>
          </Card>
        </LayoutSignIn>
      )}
    </>
  )
}

SignIn.requireAuth = false
SignIn.requireOrg = false

export default SignIn
