import type { FC } from 'react'

import { IconProps } from './'

export const GoogleIcon: FC<IconProps> = ({ className, ...other }) => {
  return (
    <svg
      className={className}
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.42 14.8296C28.42 13.8014 28.3277 12.8128 28.1564 11.8637H14.5V17.4725H22.3036C21.9675 19.285 20.9459 20.8207 19.4102 21.8489V25.4871H24.0964C26.8382 22.9628 28.42 19.2455 28.42 14.8296Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 29C18.415 29 21.6973 27.7016 24.0964 25.4871L19.4102 21.8489C18.1118 22.7189 16.4509 23.233 14.5 23.233C10.7234 23.233 7.52683 20.6823 6.3866 17.255H1.54228V21.0118C3.92819 25.7507 8.83183 29 14.5 29Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.38659 17.255C6.09659 16.385 5.93182 15.4557 5.93182 14.5C5.93182 13.5444 6.09659 12.615 6.38659 11.745V7.98822H1.54227C0.560227 9.94572 0 12.1603 0 14.5C0 16.8398 0.560227 19.0544 1.54227 21.0119L6.38659 17.255Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 5.76705C16.6289 5.76705 18.5402 6.49864 20.043 7.93545L24.2018 3.77659C21.6907 1.43682 18.4084 0 14.5 0C8.83183 0 3.92819 3.24932 1.54228 7.98818L6.3866 11.745C7.52683 8.31773 10.7234 5.76705 14.5 5.76705Z"
        fill="#EA4335"
      />
    </svg>
  )
}

export default GoogleIcon
